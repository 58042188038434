<template>
    <MainAppContainer>
        <Success />
    </MainAppContainer>
</template>
<script>
import MainAppContainer from '@/components/containers/MainAppContainer.vue'
import Success from '@/components/booking/Success.vue'

export default {
  components: {
    MainAppContainer,
    Success
  }
}
</script>
