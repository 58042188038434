<template>
    <the-header />
        <slot/>
    <the-footer/>

</template>

<script>
import TheFooter from './../common/TheFooter'
import TheHeader from '@/components/common/header/TheHeader'
import 'bootstrap/dist/css/bootstrap.min.css'
import Echo from 'laravel-echo';

import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

export default {
    name: 'MainAppContainer',
    components: {
        TheFooter,
        TheHeader
    },
    created(){
        axios.defaults.headers.common['Authorization'] = 'Bearer '+this.$storage.getStorageSync('token')

        window.Pusher = require('pusher-js');

        window.Echo = new Echo({
            authEndpoint: process.env.VUE_APP_BASE_URL+'broadcasting/auth', 
            broadcaster: process.env.VUE_APP_BROADCAST_DRIVER,
            key: process.env.VUE_APP_PUSHER_APP_KEY,
            cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
            encrypted: process.env.VUE_APP_WEBSOCKETS_ENCRYPT,
            wsHost: window.location.hostname,
            wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
            wssPort: process.env.VUE_APP_WEBSOCKETS_PORT,
            forceTLS: true,
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
            auth: {
                headers: {
                    Authorization: 'Bearer ' + this.$storage.getStorageSync('token')
                },
            },
        });
    }
}
</script>
<style>
@import './../../assets/css/style.css';
</style>