<template>
    <div class="container my-4 py-4 my-md-5 py-md-5">
        <div class="text-center mb-md-5 mb-4">
            <div class="calendar-icon-success">
                <div class="booked-month">  {{ booking_detail?.booking_date && moment(booking_detail.booking_date).format('DD, MMM') }}</div>
                <div class="booked-date"><fa icon="calendar-alt" /></div>
                <div class="green-circle-div"><img :src="assetUrl('booking/confirmation.svg')"><!-- <fa icon="check" /> --></div>
            </div>
            <div class="mb-3 mt-1 h5 text-muted">Booking Ref: {{ booking_detail?.tracking_id }}</div>
            <div class="h3 mb-3">Thank you for booking an appointment with "{{ booking_detail?.business_name }}"</div>
            <p class="text-dark text-center m-auto" style="max-width: 650px;">You will receive a conirmation email, reminder email and text messages to update you about your appointments. The <a href="#!" class="text-decoration-underline">Cancellation</a> and <a href="#!" class="text-decoration-underline">No-show</a> policies are strict.</p>
            <router-link :to="{name:'userAppointments'}" class="btn ond-btn-p mt-5 py-3 py-md-3 px-4 px-md-5">Go to appointments</router-link>
        </div>
    </div>
</template>
<script setup>
import {ref, onMounted} from 'vue'
import { useRoute } from 'vue-router'
import moment from 'moment'
import { getOrderDetails } from "@/components/booking/utils"
const booking_detail = ref()
const route = useRoute()
onMounted(async () => {
    booking_detail.value = await getOrderDetails(route.params.booking_id)
})
</script>
<style scoped>
    .calendar-icon-success {
        background-color: #fff;
        width: 120px;
        margin: 0 auto 42px;
        border-radius: 10px;
        box-shadow: 0 3px 10px 0 rgb(0 0 0 / 20%);
        position: relative;
    }
    .booked-month {
        height: 40px;
        border-radius: 10px 10px 0 0;
        line-height: 40px;
        background-color: #632a6e;
        color: #fff;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: .5px
    }
    .booked-date {
        color: #333;
        height: 80px;
        font-size: 50px;
        line-height: 80px
    }
    .green-circle-div {
        /*background-color: #4bb665;*/
        width: 40px;
        height: 40px;
        line-height: 40px;
        position: absolute;
        left: 100px;
        top: 92px;
        border-radius: 50%;
        font-family: 'Fontawesome pro 400', sans-serif;
        color: #fff;
        font-size: 22px
    }
    .btn:hover {
        color:white;
    }
</style>